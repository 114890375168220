<template>
  <div class="home-footer">
   <div class="footer-item item-1"><h3>Decentralized Applications</h3></div>
   <div class="footer-item item-2"><h3>Quantum Computing</h3></div>
   <div class="footer-item item-3"><h3>3D Technologies</h3></div>
  </div>
</template>
<script>
export default {
  name: 'HomeFooter',
  props: {
  }
}
</script>

<style scoped>
.home-footer{
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
   font-weight: 300;
}

.footer-item{
    width:34%;
    text-align: center;
  
}

.item-1{
    background-color: orangered;
    color:white;
    font-weight: 300;
}
.item-2{
    color: white;
    background-color:black;
    font-weight: lighter;
}

.item-3{
    background-color: orangered;
    color:white;
    font-weight: 300;
}


</style>