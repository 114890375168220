<template>
  <div>
    <HomeHeader/>
      <div class="logo-container">
        <img class="logo-image" alt="kreatek logo" src="./assets/kreatek-logo.png">
      </div> 
    <HomeFooter/>
  
     <Particles
    id="tsparticles"
    :options="{
      background: {
        color: {
          value: '#fff'
        }
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'window',
        events: {
          onClick: {
            enable: true,
            mode: 'push'
          },
          onHover: {
            enable: true,
            mode: 'repulse'
          },
          resize: true
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
            speed: 3
          },
          push: {
            quantity: 4
          },
          repulse: {
            distance: 200,
            duration: 0.4
          }
        }
      },
      particles: {
        color: {
          value: '#E45826'
        },
        links: {
          color: '#000',
          distance: 150,
          enable: true,
          opacity: 0.1,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 2,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 800
          },
          value: 80
        },
        opacity: {
          value: 0.5
        },
        shape: {
          type: 'circle'
        },
        size: {
          random: true,
          value: 5
        }
      },
      detectRetina: true
    }"
  />
  </div>
</template>

<script>

import HomeFooter from './components/HomeFooter.vue';
import HomeHeader from './components/HomeHeader.vue';


export default {
  name: 'App',
  components: {
    HomeFooter,
    HomeHeader
  }
}

</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');

html, body {
  font-family: 'Exo', sans-serif;

margin:0;

padding:0;
}

#app {
    font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
 margin:0;

padding:0 !important;
}

.logo-container{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-60%);
  -webkit-transform: translate(-50%,-60%);
  transform: translate(-50%,-60%);
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: -10;
}
</style>
