<template>
  <div class="home-header">
    <h1><span class="orange">Kreatek</span> Information <span class="orange">Systems</span></h1>
  </div>
</template>
<script>
export default {
  name: 'HomeHeader',
  props: {
  }
}
</script>

<style scoped>
h1.home-header{
    color: orange;
}
.orange
{
    color:orangered;
}
</style>